/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

import { useTheme } from "../../core/StarberryComponentsMui"
import {
    MYACCOUNT_CONFIRMATION
} from "../../core/constants/urls";

import Confirmation from "../../core/components/MyAccount/pages/confirmation";
import PageHeaderHelmet from "../../core/components/pageHeader";

import _myAccount from "../../core/sample/myAccount.yaml"

const MyAccountPages = () => {

    const theme = useTheme()

    // In real use, the people in 'persons' will probably come from CMS,
    // while properties come from another data source.
    const myAccount =
        theme.getProp("subthemes.myAccount.sampleData") ?? _myAccount

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <Confirmation path={MYACCOUNT_CONFIRMATION} myAccount={myAccount} theme={theme} />
            </Router>
        </>
    )
}

export default MyAccountPages
