import * as React from 'react';
import { get, isEmpty } from 'lodash';
import Actions from '../../../actions';
import Alert from '../../../alert';
import StyledDialogTitle from '../../title';
import { useFormState } from '../../../../../services';
import GlobalForm from "../../../forms/"
import { collectFormValues, validateFormFields } from "../../../forms/utils"
import actionMessageFields from "../../../forms/Forms/actionFeeback"
import { trackFeedback } from '../../../helper/eventTracking';
import configs from '../../../actions/action/feedback/defaults';
import CircularProgress from '../../../progress/CircularProgress'
import { Button, Grid } from '@mui/material';

const ActionsDialogFeedback = (props) => {
    const {
        dialogTitleProps,
        code,
        type,
    } = props

    const { state, services } = useFormState()

    let actionData = state?.actionData || null;

    React.useEffect(() => {
        services.getTokenValues({code: code, type: type})
    }, [])

    // If form value other it should open custom feedback from
    // passing function to handle that
    const handleCustomComment = (event) => {
        services.changeFormState({step: 'actionOtherFeedback'});
    }

    // override values to the fields
    let newValue = {
        funcForValue:'other',
        funcForValueFunc:handleCustomComment,
    }

    // re organise the fields values with the new values
    let customFields = [...actionMessageFields];
    customFields[0] = {...customFields[0], ...newValue}
    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message: "", severity: "" }
    const formData = { submitLabel: "Post Feedback" }
    const onSubmit = event => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);

        if (ref.current.checkValidity() !== false) {
            const actionParams = get(configs, 'props.actionItemDefaults.applicant.actionFeedback', []);
            const btnParams = get(actionParams, 'formParams', {})

            const params = {
                ...btnParams,
                appointment_id: actionData.crm_id,
                successMessage: "Viewing Feedback Posted Successfully. Please visit your Dashboard to further action.",
                trackData: () => {
                    trackFeedback({
                        eventLabel: values.followup,
                        eventRole: props.userRole
                    })
                }
            }
            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }


    if (state.loading) {
        return <CircularProgress />
    }

    return(
        <>
            {isEmpty(actionData) && (
                <Grid classes={{root: "alertContainer"}}>
                    <Alert type="alert" classes="actionCardDialogChildren-alert" message={state.message} severity={state.error ? 'error': 'success'} />
                    <Button variant="contained" href="/">Go to dashboard</Button>
                </Grid>
            )}
            
            {!isEmpty(actionData) && (
                <>
                    <StyledDialogTitle
                        id="acionDialog-responsive-dialog-title"
                        title={`Please update your   feedback for the following appointment`}
                        dialogTitleProps={dialogTitleProps}
                    />
                    <Actions data={[{...actionData, showActionButton: false}]} />

                    <div className={`globalFormFeedback`}>
                        <GlobalForm
                            formFields={formFields}
                            handleSubmit={onSubmit}
                            formStateType={formStateType}
                            messageDialog={messageDialog}
                            data={formData}
                            formState={state}
                            ref={ref}
                        />
                    </div>
                </>
            )}
        </>
    )
}

export default ActionsDialogFeedback;
