import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import ActionDialog from "../modals/actionDialog";

const MyAccountConfirmation = withSubtheme((props) => {
    const {type, code, location} = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <ActionDialog onClose={() => void 0} code={code} type={type} location={location} />
            </Grid>
        </Grid>
    )
}, 'actionDialog')

export default React.memo(MyAccountConfirmation);
